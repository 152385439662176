<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">

          <img src="@/assets/logo.png" style="width: 100px; border-radius: 50%;   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;" />
        </div>

        <div class="card-body mb-47 mt-2 white">
          <p class="t1 mb-3 text-center">
            <strong>ข้อกำหนดและเงื่อนไขการใช้บริการ Line Application “{{
                name_line
            }}” สำหรับการใช้งานแอปพลิเคชัน ในฐานะบุคคลทั่วไป</strong>
          </p>
          <p class="t2" style="text-indent: 40px">
            ข้าพเจ้า ซึ่งเป็นบุคคลทั่วไป ตกลงใช้บริการ
            <strong>Line Application</strong>
            “{{ name_line }}” โดย รับทราบ ยอมรับ
            และตกลงที่จะผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการ
            ดังต่อไปนี้
          </p>
          <ol class="t2 pl-5 sub-items mb-10" style="line-height: 180%">
            <li class="level-1">
              <strong><u>นิยามศัพท์</u></strong>
              <ol class="sub-items indent">
                <li>
                  <strong>“แอปพลิเคชัน”</strong>
                  หรือ
                  <strong>“Application”</strong>
                  หมายความว่า
                  <strong>Line Application “{{ name_line }}”</strong>
                  ตลอดจนโปรแกรมคอมพิวเตอร์ ชุดคำสั่ง หรือแอปพลิเคชันอื่น ๆ
                  ที่ต้องใช้หรืออาจใช้ร่วมกันหรือเกี่ยวเนื่องกันกับ Line
                  application ดังกล่าว เพื่อการใช้บริการ
                  <strong>“{{ name_line }}”</strong>
                  ไม่ว่าจะใช้งานผ่านโทรศัพท์มือถือ แท็บเล็ต เว็บ
                  หรือผ่านอุปกรณ์อื่นใดก็ตาม ทั้งนี้ ในกรณีที่ Line application
                  ดังกล่าวเปลี่ยนชื่อเป็นอย่างอื่นหรือมีแอปพลิเคชันอื่นรวมอยู่ด้วย
                  ให้หมายความรวมถึงแอปพลิเคชันนั้นด้วย
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="next" class="button">ยอมรับ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
export default defineComponent({
  name: "Register",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const name_line = ref(process.env.VUE_APP_NAME_LINE);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const next = () => {
      let form = {
        id_line: store.getters.getAccessToken.accesstoken,
        agreement: true,
      };
      store.dispatch(SET_REGISTER, form);
      router.push({ name: "Register_1" });
    };

    return {
      name_line,
      next,
    };
  },
});
</script>

<style scoped>

</style>
