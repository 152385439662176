export const SET_ZONE = "setZone";

export default {
  state: {
    zone: [],
  },
  getters: {
    getZone(state) {
      return state.zone;
    },
  },
  actions: {
    [SET_ZONE](state, payload) {
      state.commit(SET_ZONE, payload);
    },
  },
  mutations: {
    [SET_ZONE](state, payload) {
      state.zone = [
        ...state.zone,
        ...payload,
      ];
    },
  },
};
