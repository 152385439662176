export const SET_PROVINCE = "setProvince";

export default {
  state: {
    province: [],
  },
  getters: {
    getProvince(state) {
      return state.province;
    },
  },
  actions: {
    [SET_PROVINCE](state, payload) {
      state.commit(SET_PROVINCE, payload);
    },
  },
  mutations: {
    [SET_PROVINCE](state, payload) {
      state.province = {
        ...state.province,
        ...payload,
      };
    },
  },
};
