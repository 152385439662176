import { createStore } from "vuex";

import ProfireLine from "@/store/modules/ProfireLine";
import AuthModule from "@/store/modules/AuthModule";
import RegisterModel from "@/store/modules/RegisterModel";
import Province from "@/store/modules/Province";
import Zone from "@/store/modules/Zone";
const store = createStore({
  modules: {
    ProfireLine,
    AuthModule,
    RegisterModel,
    Province,
    Zone
  },
});

export default store;
